import React from 'react';

import { AttentionDisable } from '@travel/icons/ui';

import ErrorPage from 'pages/ErrorPage';

import { Translate } from 'core/translate';
import { Link } from 'core/universalRouter/Link';
import paths from 'core/universalRouter/paths';

function NotFoundPage() {
  return (
    <ErrorPage
      icon={<AttentionDisable color="lightGrey" />}
      mainText={<Translate id="Traveler_Server_Error.a.3.Message1" />}
      link={
        <Link to={paths.top.pathResolver()}>
          <Translate id="Traveler_Server_Error.a.3.Navigation" />
        </Link>
      }
    />
  );
}

export default NotFoundPage;
